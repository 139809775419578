import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <section className="mx-4">
        <div className="container-1">
          <div className="my-12">
              <h1 className="mb-6 title text-3xl font-bold text-text-primary">
                Contact
              </h1>
              <form
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="mb-3">
                  <label className="label" htmlFor={"name"}>
                    Your name
                  </label>
                  <div className="box-border clear-both text-base relative"> {/*/control*/}
                    <input
                      className="shadow-outline max-w-full w-full bg-white border-gray-300 rounded text-text-primary border-solid border items-center inline-flex justify-start leading-6 py-2 px-3 relative align-top"
                      type={"text"}
                      name={"name"}
                      onChange={this.handleChange}
                      id={"name"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="label" htmlFor={"email"}>
                    Email
                  </label>
                  <div className="box-border clear-both text-base relative">
                    <input
                      className="shadow-outline max-w-full w-full bg-white border-gray-300 rounded text-text-primary border-solid border items-center inline-flex justify-start leading-6 py-2 px-3 relative align-top"
                      type={"email"}
                      name={"email"}
                      onChange={this.handleChange}
                      id={"email"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="label" htmlFor={"message"}>
                    Message
                  </label>
                  <div className="box-border clear-both text-base relative">
                    <textarea
                      className="h-64 max-h-full min-h-1/2 border-gray-300 border-solid border block max-w-full min-w-full p-3 resize-y shadow-outline w-full bg-white rounded text-text-primary" /*/h-64 max-h-full min-h-1/2 border-gray-300 border-solid border block max-w-full min-w-full p-3 resize-y shadow-outline w-full bg-white rounded text-gray-600 textarea*/
                      name={"message"}
                      onChange={this.handleChange}
                      id={"message"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="">
                  <button className="bg-blue-600 text-white border-transparent border cursor-pointer justify-center py-2 px-4 text-center whitespace-no-wrap rounded" type="submit"> {/*/ button is-info/*/}
                    Send
                  </button>
                </div>
              </form>
          </div>
        </div>
        </section>
      </Layout>
    );
  }
}
